<template>
  <div class="p-grid crud-demo">
    <Toast />
    <div class="p-col-12 p-md-6">
      <div class="card">
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb*2"
              @click="openCategoria"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="categorias"
          class="p-datatable-sm p-datatable-striped"
          v-model:selection="selectedCargo"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 15, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} categorias."
        >
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Categorias</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global']"
                  placeholder="Pesquisar..."
                />
              </span>
            </div>
          </template>
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>

          <Column
            field="descricao"
            header="Descrição da Categoria"
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Descrição</span>
              {{ slotProps.data.descricao }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="openEditCategoria(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="openDeleteCategoria(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <!-- CADASTRAR E ATUALIZAR CATEGORIA-->
        <Dialog
          v-model:visible="categoriaDialog"
          :style="{ width: '480px' }"
          :header="titleDialog"
          :modal="true"
          @hide="hideDialog"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="descricao">Descreva a categoria</label>
            <InputText
              id="descricao"
              v-model.trim="categoria.descricao"
              required="true"
              autofocus
              placeholder="Informe a categoria."
              maxlength="255"
              :class="{ 'p-invalid': submitted && !categoria.descricao }"
            />
            <small class="p-invalid" v-if="submitted && !categoria.descricao"
              >Campo {{ msgUsuario }}.</small
            >
          </div>
          <template #footer>
            <Button
              label="Salvar"
              icon="pi pi-check"
              class="p-button-raised"
              @click="sendCategoria"
            />
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>
        <!-- FIM CADASTRAR E ATUALIZAR CATEGORIA-->

        <!-- EXCLUIR CATEGORIA-->
        <Dialog
          v-model:visible="deleteCategoriaDialog"
          :style="{ width: '450px' }"
          :modal="true"
          @hide="hideDialog"
          header="Mensagem de Confirmação!"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="categoria"
              >Tem certeza de que deseja excluir o registro
              <b>{{ categoria.descricao }} ?</b></span
            >
          </div>
          <template #footer>
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-raised"
              @click="deleteCategoria"
            />
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>
        <!-- FIM EXCLUIR CATEGORIA-->
      </div>
    </div>
  </div>
</template>
<script>
import AuthService from "@/service/Auth/AuthService";
import CategoriaService from "@/service/Categoria/CategoriaService";

export default {
  data() {
    return {
      categorias: null,
      categoria: {
        id: null,
        descricao: null,
      },
      categoriaDialog: false,
      deleteCategoriaDialog: false,
      selectedCategoria: null,
      filters: {},
      submitted: false,
      titleDialog: null,
      msg: null,
      msgUsuario: null,
      msgDesenvolvedor: null,
      reqError: false,
    };
  },
  categoriaService: null,
  authService: null,
  created() {
    this.authService = new AuthService();
    this.categoriaService = new CategoriaService();
  },
  mounted() {
    this.authService.checarToken(this.listCategorias());
  },
  methods: {
    /*METODO RESPONSAVEL PELA ABERTURA DA MODAL DE CADASTRAR CATEGORIA*/
    openCategoria() {
      this.titleDialog = "Nova Categoria";
      this.categoriaDialog = true;
    },
    /*METODO RESPONSAVEL PELA ABERTURA DA MODAL DE EDITAR CATEGORIA*/
    openEditCategoria(categoria) {
      this.categoria = categoria;
      this.titleDialog = this.categoria.descricao;
      this.categoriaDialog = true;
    },
    /*METODO RESPONSAVEL PELA ABERTURA DA MODAL DE DELETAR CATEGORIA*/
    openDeleteCategoria(categoria) {
      this.categoria = categoria;
      this.deleteCategoriaDialog = true;
    },
    /*METODO RESPONSAVEL PELA LISTAGEM DA CATEGORIA*/
    listCategorias() {
      this.categoriaService.listar().then((data) => {
        this.categorias = data;
      });
    },
    /*METODO RESPONSAVEL POR SALVAR E EDITAR UMA NOVA CATEGORIA*/
    saveCategoria() {
      this.submitted = true;
      if (this.categoria.id == null) {
        this.categoriaService
          .criar(this.categoria)
          .then((data) => {
            if (data.status === 201) {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Categoria salva com sucesso.",
                life: 3000
              });
              this.listCategorias();
              this.hideDialog();
            }
          })
          .catch((error) => {
            this.reqError = true;
            this.categoria.descricao = null;
            this.msgUsuario = error.response.data[0].mensagemUsuario;
            this.msgDesenvolvedor =
              error.response.data[0].mensagemDesenvolvedor;
          });
      } else {
        this.categoriaService
          .atualizar(this.categoria.id, this.categoria)
          .then((data) => {
            if (data.status === 200) {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Categoria atualizada com sucesso.",
                life: 3000
              });
              this.hideDialog();
            }
          });
      }
    },
    /*METODO RESPONSAVEL POR DELETAR UMA CATEGORIA*/
    deletarCategoria() {
      this.categoriaService.remover(this.categoria.id).then((data) => {
        if (data.status === 204) {
          this.listCategorias();
          this.hideDialog();
          this.$toast.add({
            severity: "success",
            summary: "Sucesso!",
            detail: "Categoria deletada com sucesso.",
            life: 3000
          });
        } else {
          this.$toast.add({
            severity: "erro",
            summary: "Erro!",
            detail: "Erro ao deletar registro.",
            life: 3000,
          });
        }
      });
    },
    /*METODOS RESPONSAVEIS POR PELA CHEKAGEM DE TOKEN NA OPERAÇÕES DE CADASTRAR,ALTERAR E EXCLUIR*/
    sendCategoria() {
      this.authService.checarToken(this.saveCategoria());
    },
    deleteCategoria() {
      this.authService.checarToken(this.deletarCategoria());
    },
    /*METODOS RESPONSAVEIS PELO RESET DAS MODAIS*/
    hideDialog() {
      this.categoriaDialog = false;
      this.categoriaDialog = false;
      this.deleteCategoriaDialog = false;
      this.submitted = false;
      this.categoria = {};
      this.msgUsuario = null;
      this.msgDesenvolvedor = null;
    },
  },
};
</script>

<style scoped lang="scss">
.img-fluid {
  max-width: 100%;
  height: auto;
}
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.setor-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.p-dialog .setor-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.setor-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }
  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }
  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}
.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}
@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }
      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }
        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}
</style>