import axios from "axios";

import AuthService from '../Auth/AuthService';

export default class CategoriaService extends AuthService {

  /* CADASTRAR */
  criar(pessoa) {
    return axios({
      method: 'post',
      url: this.api_url + 'categoria',
      withCredentials: true,
      data: pessoa,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  /* REMOVER */
  remover(id) {
    return axios({
      method: 'delete',
      url: this.api_url + 'categoria/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });

  }
  /* ATUALIZAR */
  atualizar(id, pessoa) {
    return axios({
      method: 'put',
      url: this.api_url + 'categoria/' + id,
      withCredentials: true,
      data: pessoa,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  /* LISTAR TODOS */
  listar() {
    return axios({
      method: 'get',
      url: this.api_url + 'categoria',
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }
}
